<template>
   <a-card title="">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <ve-pie  
                    judge-width
                    :data="item"  
                    :legend="{
                    type:'scroll',
                    orient: 'vertical',
                    left:'0%',
                    align:'left',
                    top:'middle',
                    textStyle: {
                        color:'#8C8C8C'
                    },
                    height:150}" 
                    :title="{
                        text:`${title}（${item.title?item.title:'0'}）`,
                        subtext: `累计至 ${end_date}`,
                        textStyle: {
                            fontSize: 15,
                        },
                        subtextStyle: {
                            fontSize: 12.5
                        },
                    }" 
                    :extend="{
                        series:{
                            label: {
                                normal: {
                                    formatter: params => {
                                        return (
                                            `${params.name} ${params.value} (${params.percent}%)`
                                        );
                                    }
                                }
                            }
                        }
                    }">
            </ve-pie>
            <LEmpty v-if="first>0 && item.rows.length == 0"/>
            </div>
    </a-card>
</template>

<script>
    export default {
        name:'gender',
        data () {
             this.chartSettings = {
                dataType: 'percent'
            },
            this.toolbox = {
                feature: {
                magicType: {},
                saveAsImage: {pixelRatio:2}
                }
            }
            return{
                first:0
            }
        },
        props:['item' , 'start_date', 'end_date','loading','title'],
        watch: {
            loading(newValue, oldValue) {
                this.first++;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>