<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>在籍学员分析</a-breadcrumb-item>
            <a-breadcrumb-item>学员分析</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>


                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>所在校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择所在校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>

                    
                    <a-form-item>
                        <a-button @click="toScreen" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="dashboard bk-group" >
                <statistics :count='counts' col="4" />
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="12">
                        <gender :loading='loadingG' title="学员性别统计" :item ='genderData' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <age :loading='loadingA' :item ='ageData' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <LRanking title="学员统计" :rank_data="summates" :loading="loadingSS" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />    
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <fee :loading='loadingF' :item ='feeData' title="学员课时分布"  :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                    </a-col>

                    <a-col :lg="24" :xl="24">
                        <studentL title="学员入读统计" :loading='loading'  :leads_data ='incomeData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
    import studentL from './studentL'
    import gender from './gender'
    import age from './age'
    import fee from './fee'
    import moment from 'moment'
    import ranges from "@/common/mixins/ranges"
    import statistics from '@/views/bi/ranking/money/statistics'
    export default {
        name:'xytj',
        components:{
            age,
            fee,
            gender,
            studentL,
            statistics
        },
        data() {
            return {
                loading: false,
                loadingG:false,
                loadingA:false,
                loadingSS:false,
                loadingF:false,
                incomeData:{},
                genderData:{},
                ageData:{},
                feeData:{},
                summates:{},
                counts:[],
                paramsData:{
                    start_date:moment().startOf('month').format('YYYY-MM-DD'),
                    end_date:moment().format('YYYY-MM-DD'),
                    filter_type:'day'
                },
                studios:[],
            }
        },
        mixins: [ranges],
        created(){
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.toScreen()
            this.getStudio()
        },
        methods:{
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            getList(obj){
                this.getCount(obj)
                this.getAge(obj)
                this.getIncome(obj)
                this.getGender(obj)
                this.getSummate(obj)
                this.getFee(obj)
            },
            async getIncome(obj){
                this.loading = true
                let res = await this.$store.dispatch('analysisStudentsAction', obj)
                this.incomeData = res.data
                this.loading = false
            },
            async getGender(obj){
                this.loadingG = true
                let res = await this.$store.dispatch('analysisStudentsGenderAction', obj)
                this.genderData = res.data
                this.loadingG = false
            },
            async getAge(obj){
                this.loadingA = true
                let res = await this.$store.dispatch('analysisStudentsAgeAction', obj)
                this.ageData = res.data
                this.loadingA = false
            },
            async getSummate(obj){
                this.loadingSS = true
                let res = await this.$store.dispatch('analysisStudentsSummateAction', obj)
                this.summates = res.data
                this.loadingSS = false
            },
            async getFee(obj){
                this.loadingF = true
                let res = await this.$store.dispatch('analysisStudentsFeeAction', obj)
                this.feeData = res.data
                this.loadingF = false
            },
            async getCount(obj){
              let res = await this.$store.dispatch('analysisStudentsCountAction', obj)
              this.counts = res.data
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {})
                this.studios = res.data
            },
            handleChange(value){
                this.paramsData.studio_id = value;
            },
            handleButChange(e){
                this.paramsData.filter_type = e.target.value
                this.toScreen()
            },
            toScreen(){
                let obj = {
                    search:this.paramsData
                }
                this.getList(obj);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>